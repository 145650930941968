<template>
  <el-dialog
    title="服务记录"
    :visible.sync="showFlow"
    width="90%"
    height="80vh"
    top="5vh"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
  >
    <el-table
      v-loading="dataListLoading"
      :data="dataList"
      border
      @selection-change="dataListSelectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="createDate"
        label="时间"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="desc"
        label="行为描述"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="intervalTime"
        label="间隔时长"
        header-align="center"
        align="center"
      ></el-table-column>
      <!-- 
                <el-table-column
                    prop="inquiryType"
                    label="问诊类型"
                    header-align="center"
                    align="center"
                    ><template slot-scope="scope">{{
                        typename(scope.row.inquiryType)
                    }}</template></el-table-column
                > -->
    </el-table>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
const apiUrls = {
  flow: '/trtc/flow/list',
};
export default {
  mixins: [mixinViewModule],
  props: {
    uuid: {
      type: String,
      required: true,
    },
    showFlow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/trtc/flow/list',
        getDataListIsPage: false,
        // deleteURL: '/sys/smslog',
        deleteIsBatch: true,
      },
      dataForm: {
        uuid: this.uuid,
      },
    };
  },
  watch: {
    async uuid(val, oldVal) {
      if (val && val !== oldVal && this.showFlow) {
        this.dataForm.uuid = val;
        this.getDataList();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit('changeFlowState');
    },
  },
};
</script>

<style></style>
